const AccountInvitations = {
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        userModel: null,
        loadTrigger: 0,
        invitations: null
    },
    data: () => ({
    }),
    template: "#AccountInvitations"
}

export default AccountInvitations

const VisibilityHelper = {
    getVisibilityOfAllChildren(model) {
        let visibility = false;
        if (model.children != undefined && model.children.length > 0) {
            for (const idx in model.children) {
                let child = model.children[idx];
                if (this.getVisibilityOfAllChildren(child,)) {
                    visibility = true;
                }
            }
        } else {
            if (model.isVisible != false) {
                visibility = true;
            }
        }
        return visibility;
    }
}

export default VisibilityHelper

<template>
    <button v-bind="$attrs"
            :class="getClass()"
            @click="processClick"
            :tabindex="tabindex">
        <slot></slot>
    </button>
</template>
<script>
    export default {
        emits: ["click"],
        name: "v_button",
        props: {
            class: "",
            tabindex: 0
        },
        methods: {
            getClass() {
                return "traq-btn yds-btn-secondary" + /*  center-button yds-button-no-border yds-button-no-background " +*/
                    (this.class == undefined ? "" : " " + this.class);
            },
            processClick() {
                this.$emit("click");
            }
        }
    }
</script>
<template>
    <div>
        <div v-for="(message, index) of localValidationMessages">
            <label :class="getClassOutput()">{{message}}</label>
        </div>
    </div>
</template>
<script>
    import ValidationHelper from '/wwwroot/js/vue/validatorHelper.js';

    export default {
        emits: ["validation-error-found-on", "update:validation-messages"],
        props: {
            modelName: null,
            modelValue: null,
            modelContainer: null,
            validatorArguments: null,
            needsValidationOn: null,
            validationMessages: null,
            class: null
        },
        data: () => ({
            localValidationMessages: null,
            validators: []
        }),
        created() {
            window.addEventListener("clearValidationMessages", () => this.clearValidationMessages());
            if (this.validatorArguments != null) {
                this.validators = JSON.parse(this.validatorArguments);
            }
        },
        unmounted() {
            window.removeEventListener("clearValidationMessages", () => this.clearValidationMessages());
        },
        methods: {
            clearValidationMessages() {
                if (this.validationMessages != null) {
                    this.localValidationMessages = [];
                }
            },
            getClassOutput() {
                return this.class ?? "text-danger yds-fs-12";
            }
        },
        watch: {
            needsValidationOn(newValue, oldValue) {
                if (newValue == true) {
                    this.localValidationMessages = ValidationHelper.processValidators(this.$parent, this.modelValue, this.validators, this.modelContainer, this.modelName);
                    this.validationMessages[this.modelName] = this.localValidationMessages;
                    this.$emit("update:validation-messages", this.validationMessages);
                }
            },
        },
        name: "v_validator"
    };
</script>
import ValidationMixin from "/wwwroot/js/vue/validationMixin.js";
import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

const VendorEditModal = {
    mixins: [ValidationMixin],
    emits: ["updated:edit-vendor-model"],
    props: {
        clientId: 0,
        globalConfigViewModel: null,
        messageBoxModel: null,
        editVendorModel: null,
        recordIsReadOnly: false,
        emptyItemModel: null
    },
    data: () => ({
        apiBody: null,
        apiTrigger: 0,
        tabList: null,
        vendorEditLoadTrigger: 0,
        vendorCheckoutLoadTrigger: 0,
        vendorCurrentLoadTrigger: 0,
        vendorHistoryLoadTrigger: 0,
        tabIndex: 0
    }),
    methods: {
        upsertError(error) {
            console.log("ERROR", error);
        },
        upsertResponse(response) {
            switch (response.data.responseHeader.statusCode) {
                case StatusCodes.NoError:
                    this.$emit("update:edit-vendor-model", response.data.envelope);
                    this.globalVendorEditViewModel.isEditVendorVisible = false;
                    break;

                case StatusCodes.VendorAlreadyExists:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "A designer with this name combination already exists.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;

                case StatusCodes.VendorMissingName:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "A valid name combination is missing.  Please update it and try again.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;

                default:
                    console.log("UNKNOWN", response);
                    break;
            }
        },
        closeButtonClick: function () {
            this.globalVendorEditViewModel.isEditVendorVisible = false;
        },
    },
    computed: {
        isEditVendorVisible: {
            get() {
                return this.globalVendorEditViewModel.isEditVendorVisible;
            }
        }
    },
    watch: {
        editVendorModel(newValue, oldValue) {
            console.log("watch response from editVendorModel");
            this.$emit("updated:edit-vendor-model", newValue);
        },
        globalVendorEditViewModel: {
            handler(newValue, oldValue) {
                console.log("vendorEditModal.globalVendorEditViewModel");
                if (this.isEditVendorVisible == true) {
                    let tabList = [
                        {
                            label: "Vendor",
                            class: "",
                            visible: true,
                            beforeChange: () => {
                                this.vendorEditLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "Checkout",
                            class: "",
                            visible: !this.recordIsReadOnly && this.editVendorModel?.id > 0 && this.editVendorModel.isVendor == false,
                            beforeChange: () => {
                                this.vendorCheckoutLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "Current",
                            class: "",
                            visible: this.editVendorModel?.id > 0 && this.editVendorModel.isVendor == false,
                            beforeChange: () => {
                                this.vendorCurrentLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "History",
                            class: "",
                            visible: !this.recordIsReadOnly && this.editVendorModel?.id > 0 && this.editVendorModel.isVendor == false,
                            beforeChange: () => {
                                this.vendorHistoryLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        }
                    ];

                    this.tabList = tabList;
                }
            },
            deep: true
        }
    },
    created() {
    },
    template: "#VendorEditModal"
};

export default VendorEditModal;

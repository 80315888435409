<template>
    <div class="form-control-group form-outline">
        <input :name="name"
               autocomplete="off"
               @change="onChange"
               @input="onChange"
               @blur="onBlur"
               ref="input"
               :id="getId"
               :class="getFormControlInputClass() + ' form-control'"
               :type="getType()"
               :value="currentValue"
               :readonly="readonly"
               v-bind="$attrs"
               :tabindex="tabindex"/>
        <label :class="'yds-color-secondary form-control-label-inside ' + getFormLabelClass()"
               :for="getId">
            {{label}}
            <span v-if="_isRequired" class="yds-required-field">*</span>
        </label>
        <button v-if="hasClear == true || hasClear == 'true'"
                class="yds-button-no-border yds-button-no-background form-control-button-inside"
                v-on:click="clearText">
            <i class="fa-solid fa-xmark"></i>
        </button>
        <v-help-topic v-if="helpTopic"
                      class="yds-button-no-border yds-button-no-background form-control-button-inside-bottom yds-margin-right-3px"
                      :topic-id="helpTopic" />
    </div>
</template>
<script>
    import { guidHelper, guidHelperFormats } from '/wwwroot/js/common/guidHelper.js'
    export default {
        name: "v_textbox",
        emits: ["update:model-value"],
        props: {
            focus: null,
            id: null,
            label: null,
            modelValue: null,
            name: null,
            required: null,
            type: null,
            readonly: false,
            labelSize: { type: String, default: "medium" },
            hasClear: false,
            helpTopic: null,
            tabindex: 0
        },
        data: () => ({
            _id: null,
            _isRequired: false
        }),
        methods: {
            getType() {
                return this.type == undefined || this.type == null
                    ? 'text'
                    : this.type;
            },
            clearText() {
                this.$emit("update:model-value", "");
            },
            onChange(event) {
                this.$emit("update:model-value", event.target.value);
            },
            getFormControlInputClass() {
                return this.labelSize == "medium"
                    ? "form-control-input"
                    : "form-control-input-small";
            },
            getFormLabelClass() {
                return this.labelSize == "medium"
                    ? "yds-form-label"
                    : "yds-form-label-small";
            },
            onBlur() {
                if (this.type == "phone") {
                    this.formatValueToPhone();
                }
            },
            formatValueToPhone() {
                if (this.modelValue == undefined || this.modelValue == null || this.modelValue == "") {
                    return;
                }

                let originalValue = this.modelValue;
                let numbers = originalValue.replace(/[^\d]/g, "");
                let formattedValue = numbers.replace(/^(\d{3})-?(\d{3})-?(\d{4})$/, "$1-$2-$3");
                if (originalValue != formattedValue) {
                    this.$emit("update:model-value", formattedValue);
                }
            }
        },
        computed: {
            getId: {
                get: function () {
                    this._isRequired = this.required !== undefined;
                    if (this._id === null) {
                        this._id = this.id != null
                            ? this.id
                            : guidHelper.getNewGuid("INPUT_", guidHelperFormats.short);
                    }
                    return this._id;
                }
            },
            currentValue: {
                get: function () {
                    return this.modelValue;
                },
                set: function (value) {
                    if (value === this.modelValue) {
                        return;
                    }
                    this.$emit("update:model-value", value);
                }
            }
        },
        watch: {
            id(newValue, oldValue) {
                this._id = null;
                this.getId();
            }
        },
        mounted: function () {
        }
    }
</script>

<template id="ContactCurrent">
    <div>
        <v-grid :column-names="gridColumns"
                :rows="visibleRows"
                v-on:before-page-jump="beforePageJump"
                v-on:after-page-jump="afterPageJump"
                filter-on-all-fields="true">
            <template v-slot:header-column="{headerColumn, headerColumnIndex, sortDirection, triggerSort}">
                <button v-if="headerColumnIndex < 8" type="button" class="sort-button" v-on:click="triggerSort(headerColumnIndex)">
                    <strong>{{headerColumn.name}}</strong>
                    <img v-if="sortDirection == undefined" src="/img/sort_both.png" />
                    <img v-if="sortDirection == true" src="/img/sort_asc.png" />
                    <img v-if="sortDirection == false" src="/img/sort_desc.png" />
                </button>
                <div v-if="headerColumnIndex == 9" align="center">
                    <v-checkbox v-model="checkAllRowsValue" @change="checkAllRowsTrigger++"></v-checkbox>
                </div>
            </template>
            <template v-slot:row="{row, rowNum}">
                <td>{{row.displayName}}</td>
                <td>{{row.itemName}}</td>
                <td>{{row.patternName}}</td>
                <td>{{row.patternNumber}}</td>
                <td>{{row.itemTypeName}}</td>
                <td>{{row.color}}</td>
                <td>{{formatDate(row.outOnUtc)}}</td>
                <td>{{row.numDays}}</td>
                <td>
                    <button class='btn-sm yds-btn-secondary yds-contact-current-return-button' type='button' @click="returnItem(row.id)">RTN</button>
                </td>
                <td align="center">
                    <v-checkbox v-model="row.isCheckedForRtn" @change="checkRowTrigger++"></v-checkbox>
                </td>
            </template>
        </v-grid>
        <div class="row">
            <div class="col yds-cell-right">
                <button type="button" class="btn yds-btn-secondary" @click="checkInSelected" :disabled="!isCheckInSelectedButtonEnabled">Check In Selected</button>
            </div>
        </div>

        <v-api url="Checkin/CheckInItem"
               :api-trigger="checkInItemTrigger"
               :api-body="checkInItemBody"
               v-on:api-response="checkInItemResponse"
               v-on:api-error="checkInItemError" />

        <v-api url="Checkin/CheckInItemsByCheckoutIds"
               :api-trigger="checkInSelectedItemsTrigger"
               :api-body="checkInSelectedItemsBody"
               v-on:api-response="checkInSelectedItemsResponse"
               v-on:api-error="checkInSelectedItemsError" />

        <v-api url="Checkout/GetCheckoutCurrent"
               :api-trigger="getCheckoutCurrentTrigger"
               :api-body="getCheckoutCurrentBody"
               v-on:api-response="getCheckoutCurrentResponse"
               v-on:api-error="getCheckoutCurrentError" />
    </div>
</template>
<script>
    import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

    const ContactCurrent = {
        props: {
            globalConfigViewModel: null,
            messageBoxModel: null,
            editContactModel: null,
            recordIsReadOnly: false,
            timeZoneId: 2,
            loadTrigger: 0
        },
        data: () => ({
            checkInItemTrigger: 0,
            checkInItemBody: null,
            checkInSelectedItemsTrigger: 0,
            checkInSelectedItemsBody: null,
            getCheckoutCurrentTrigger: 0,
            getCheckoutCurrentBody: null,
            checkAllRowsValue: false,
            checkAllRows: false,
            checkAllRowsTrigger: 0,
            checkRowTrigger: 0,
            gridColumns: [
                {
                    name: "Vendor",
                    columnName: "displayName",
                },
                {
                    name: "Item Name",
                    columnName: "itemName"
                },
                {
                    name: "Pattern Name",
                    columnName: "patternName"
                },
                {
                    name: "Pattern #",
                    columnName: "patternNumber"
                },
                {
                    name: "Item Type",
                    columnName: "itemTypeName"
                },
                {
                    name: "Color",
                    columnName: "color"
                },
                {
                    name: "Out On"
                },
                {
                    name: "# Days"
                },
                {
                    // RTN
                    width: "7.5%"
                },
                {
                    // Checkbox
                    width: "2.5%"
                }
            ],
            rows: [],
            visibleRows: [],
            isCheckInSelectedButtonEnabled: false,
            checkoutId: 0
        }),
        methods: {
            formatDate(value) {
                let tz;
                switch (this.timeZoneId) {
                    case 1:
                        tz = "America/New_York";
                        break;

                    case 3:
                        tz = "America/Denver";
                        break;

                    case 4:
                        tz = "America/Los_Angeles";
                        break;

                    default:
                        tz = "America/Chicago";
                        break;
                }

                let options = { timeZone: tz };
                let date = new Date(value);
                return date.toLocaleDateString("en-US", options);
            },
            returnItem(checkoutId) {
                MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                    body: "Are you sure you want to check this item in?",
                    title: "Attention",
                    messageBoxType: MessageBoxType.question,
                    rightButtonText: "Yes",
                    rightButtonAction: (reason) => this.checkItemIn(checkoutId),
                    middleButtonText: "No"
                });
            },
            checkItemIn(checkoutId) {
                this.checkoutId = checkoutId;
                this.checkInItemBody = {
                    checkoutId: checkoutId
                };
                this.checkInItemTrigger++;
            },
            checkInItemResponse(response) {
                if (response.data.isSuccess == true) {
                    let row = this.rows.filter(row => row.id == this.checkoutId)[0];
                    row.isVisible = false;
                    this.visibleRows.length = 0;
                    this.visibleRows.push(...this.rows.filter(row => row.isVisible == true));
                    this.updatedCheckInSelectedButton();
                }
            },
            checkInItemError(error) {
                console.log("ERROR", error);
            },
            checkInSelected() {
                MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                    body: "Are you sure you want to check in all the selected items?",
                    title: "Attention",
                    messageBoxType: MessageBoxType.question,
                    rightButtonText: "Yes",
                    rightButtonAction: (reason) => this.checkInSelectedItems(),
                    middleButtonText: "No"
                });
            },
            checkInSelectedItems() {
                let selectedCheckouts = this.visibleRows.filter(row => row.isCheckedForRtn == true);
                let selectedIds = selectedCheckouts.map(item => item.id);
                if (selectedIds.length > 0) {
                    this.checkInSelectedItemsBody = {
                        clientId: this.editContactModel.clientId,
                        checkoutIds: selectedIds
                    };
                    this.checkInSelectedItemsTrigger++;
                }
            },
            checkInSelectedItemsResponse(response) {
                for (const index in selectedCheckouts) {
                    let checkout = selectedCheckouts[index];
                    checkout.isVisible = false;
                }
                this.refreshVisibleRows();
                this.updatedCheckInSelectedButton();
            },
            checkInSelectedItemsError(error) {
                console.log("ERROR", error);
            },
            beforePageJump(fromPageNumber, toPageNumber) {
                // About to jump pages, so clear out the check box selections.
                for (let index in this.visibleRows) {
                    let row = this.visibleRows[index];
                    row.isCheckedForRtn = false;
                }
                this.checkAllRowsValue = false;
                this.checkAllRows = false;
                this.updatedCheckInSelectedButton();
            },
            afterPageJump(fromPageNumber, toPageNumber) {
            },
            updatedCheckInSelectedButton() {
                let selectedIds = this.visibleRows.filter(row => row.isCheckedForRtn == true);
                this.isCheckInSelectedButtonEnabled = selectedIds.length > 0;
            },
            refreshVisibleRows() {
                this.visibleRows.length = 0;
                this.visibleRows.push(...this.rows.filter(row => row.isVisible == true));
            },
            pullContactModel() {
                this.getCheckoutCurrentBody =
                {
                    contactId: this.editContactModel.id
                };
                this.getCheckoutCurrentTrigger++;
            },
            getCheckoutCurrentResponse(response) {
                this.rows.length = 0;
                if (response.data.data.length > 0) {
                    for (const index in response.data.data) {
                        let row = response.data.data[index];
                        row.isCheckedForRtn = false;
                        row.isVisible = true;
                    }
                    this.rows.push(...response.data.data);
                }
                this.refreshVisibleRows();
            },
            getCheckoutCurrentError(error) {
                console.log("ERROR", error);
            }
        },
        watch: {
            loadTrigger(newValue, oldValue) {
                console.log("watch response from loadTrigger");
                this.pullContactModel();
            },
            globalContactEditViewModel: {
                handler(newValue, oldValue) {
                    console.log("watch response from globalContactEditViewModel");
                    if (this.globalContactEditViewModel.isEditContactVisible == true &&
                        this.globalItemEditViewModel.isEditItemVisible != true) {
                        this.pullContactModel();
                    }
                },
                deep: true
            },
            editContactModel: {
                handler(newValue, oldValue) {
                    console.log("watch response from editContactModel");
                    this.pullContactModel();
                },
                deep: true
            },
            checkAllRowsTrigger(newValue, oldValue) {
                console.log("watch response from checkAllRowsTrigger");
                if (this.checkAllRowsValue == this.checkAllRows) {
                    return;
                }

                this.checkAllRows = this.checkAllRowsValue;
                for (const index in this.rows) {
                    let row = this.rows[index];
                    row.isCheckedForRtn = this.checkAllRows;
                }
                this.updatedCheckInSelectedButton();
            },
            checkRowTrigger(newValue, oldValue) {
                console.log("watch response from checkRowTrigger");
                this.checkAllRowsValue = false;
                this.checkAllRows = false;
                this.updatedCheckInSelectedButton();
            }
        },
        template: "#ContactCurrent"
    };

    export default ContactCurrent;
</script>
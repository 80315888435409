<template>
    <div v-bind="$attrs">
        <a :href="'/Help?id=' + topicId"
           target="_blank"
           rel="noopener noreferrer">
            <i class="align-middle ms-2 fas fa-question-circle fa-lg"></i>
        </a>
    </div>
</template>
<script>
    export default {
        props: {
            topicId: null
        }
    }
</script>

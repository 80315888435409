const MessageBoxType = {
    information: 0,
    question: 1,
    warning: 2,
    error: 3,
    input: 4
}

const MessageBoxHelper = {
    displayMessageBox(target, source) {
        let props = Object.getOwnPropertyNames(target);
        for (const idx in props) {
            let prop = props[idx];
            target[prop] = undefined;
        }

        target["leftButtonClass"] = "yds-w-100px";
        target["middleButtonClass"] = "yds-w-100px";
        target["rightButtonClass"] = "yds-w-100px";
        target["validationMessages"] = [];

        props = Object.getOwnPropertyNames(source);
        for (const idx in props) {
            let prop = props[idx];
            target[prop] = source[prop];
        }
        target.isVisible = true;
    }
}

export { MessageBoxType, MessageBoxHelper }

<template>
    <div class="form-control-group form-outline">
        <textarea :name="name"
                  :rows="rows"
                  autocomplete="off"
                  @change="onChange"
                  @input="onChange"
                  ref="input"
                  :id="getId"
                  class="form-control-input form-control"
                  :type="type == null ? 'text' : type"
                  :value="currentValue"
                  :readonly="readonly"
                  v-bind="$attrs"
                  :tabindex="tabindex"/>
        <label class="yds-color-secondary form-control-label-inside form-label"
               :for="getId">
            {{label}}
            <span v-if="_isRequired" class="yds-required-field">*</span>
        </label>
    </div>
</template>
<script>
    import { guidHelper, guidHelperFormats } from '/wwwroot/js/common/guidHelper.js'
    export default {
        name: "v_textarea",
        emits: ["update:model-value"],
        props: {
            focus: null,
            id: null,
            label: null,
            modelValue: null,
            name: null,
            required: null,
            type: null,
            readonly: false,
            rows: 2,
            tabindex: 0
        },
        data: () => ({
            _id: null,
            _isRequired: false
        }),
        methods: {
            onChange: function (event) {
                this.$emit("update:model-value", event.target.value);
            }
        },
        computed: {
            getId: {
                get: function () {
                    this._isRequired = this.required !== undefined;
                    if (this._id === null) {
                        this._id = this.id != null
                            ? this.id
                            : guidHelper.getNewGuid("INPUT_", guidHelperFormats.short);
                    }
                    return this._id;
                }
            },
            currentValue: {
                get: function () {
                    return this.modelValue;
                },
                set: function (value) {
                    if (value === this.modelValue) {
                        return;
                    }
                    this.$emit("update:model-value", value);
                }
            }
        },
        watch: {
            id(newValue, oldValue) {
                this._id = null;
                this.getId();
            }
        },
        mounted: function () {
        }
    }
</script>

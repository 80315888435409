import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

const AccountClients = {
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        userModel: null,
        loadTrigger: 0
    },
    data: () => ({
        getClientsForUserIdBody: null,
        getClientsForUserIdTrigger: 0,
        clientColumnNames: [
            {
                name: "Client Name",
                columnName: "name"
            },
            {
                name: "Leave",
                width: "50px",
                canSort: false
            }
        ],
        clientRows: [],
        selectedClient: null,
        leaveClientBody: null,
        leaveClientTrigger: 0
    }),
    methods: {
        leaveClientResponse(response) {
            //window.location.reload();
            let filtered = this.clientRows.filter(cr => cr.id != this.selectedClient.id);
            this.clientRows.length = 0;
            this.clientRows.push(...filtered);
            this.globalConfigViewModel.clientModels.length = 0;
            this.globalConfigViewModel.clientModels.push(...filtered);
        },
        leaveClientClick(row) {
            this.selectedClient = row;
            if (row.userIsSoleAdmin == 0) {
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "You cannot leave this client because it would not have any admins remaining.  You must assign an admin role to someone else first, then you may leave.",
                        messageBoxType: MessageBoxType.error
                    });
            } else {
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "Are you sure you want to leave this client?",
                        middleButtonText: "No",
                        rightButtonText: "Yes",
                        rightButtonAction: () => this.leaveClientConfirmed(),
                        messageBoxType: MessageBoxType.question
                    });
            }
        },
        leaveClientConfirmed() {
            this.leaveClientBody = {
                clientId: this.selectedClient.id,
                userId: this.userModel.id
            };
            this.leaveClientTrigger++;
        },
        getClientsForUserIdResponse(response) {
            this.clientRows.length = 0;
            this.clientRows.push(...response.data.clients);
        }
    },
    watch: {
        loadTrigger(newValue, oldValue) {
            console.log("watch response from loadTrigger");
            this.getClientsForUserIdTrigger++;
        }
    },
    template: "#AccountClients"
}

export default AccountClients

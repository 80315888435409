import ValidationMixin from "/wwwroot/js/vue/validationMixin.js";
import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";
import { LogLevel } from '/wwwroot/js/common/logger.js'

const ItemEditModal = {
    emits: ["update:item-model", "update:edit-item-model", "update:checked-out-contact"],
    mixins: [ValidationMixin],
    props: {
        checkOutAfter: false,
        itemModel: null,
        clientId: 0,
        globalConfigViewModel: null,
        messageBoxModel: null,
        recordIsReadOnly: false,
        name: null,
        patternName: null,
        patternNumber: null,
        color: null,
        locatorCode: null,
        checkedOutContact: null,
        vendorId: 0,
        itemTypeId: 0,
        maxQuantity: 1,
    },
    data: () => ({
        isSelecting: false,
        canDelete: false,

        tabList: null,
        tabIndex: 0,

        id: 0,
        checkedOutByContacts: null,
        editItemModel: null,

        vendorListItemString: "[]",
        itemTypesListItemString: "[]",

        upsertItemTrigger: 0,
        upsertItemBody: null,

        contactGetByIdsTrigger: 0,
        contactGetByIdsBody: null,

        getVendorsByClientIdTrigger: 0,
        getVendorsByClientIdBody: null,

        getItemTypesByClientIdTrigger: 0,
        getItemTypesByClientIdBody: null,

        upsertContactTrigger: 0,
        upsertContactBody: null,
        newContact: null,

        upsertItemTypeTrigger: 0,
        upsertItemTypeBody: null,
        newItemType: null,

        deleteImagesByGuidsTrigger: 0,
        deleteImagesByGuidsBody: null,

        testValue: null
    }),
    methods: {
        imageClicked(image) {
            if (!this.isSelecting) {
                this.openImageInNewTab(image.imageUrl);
            } else {
                if (image.imageSelected == undefined) {
                    image.imageSelected = true;
                } else {
                    image.imageSelected = !image.imageSelected;
                }
            }

            this.updateCanDelete();
        },
        openImageInNewTab(imageUrl) {
            window.open(imageUrl, '_blank');
        },
        setSelecting() {
            if (this.isSelecting) {
                this.clearSelections();
            }

            this.isSelecting = !this.isSelecting;
            this.updateCanDelete();
        },
        updateCanDelete() {
            let selectedImages = this.itemModel.itemImages.filter(i => i.imageSelected == true);
            this.canDelete = selectedImages?.length > 0;
        },
        clearSelections() {
            for (const index in this.itemModel.itemImages) {
                let image = this.itemModel.itemImages[index];
                image.imageSelected = false;
            }
        },
        deleteSelection() {
            let selectedImages = this.itemModel.itemImages.filter(i => i.imageSelected == true);
            if (selectedImages?.length > 0) {
                let len = selectedImages?.length;
                let singularOrPlural = len > 1
                    ? "images"
                    : "image";

                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "You have chosen to delete " + len + " " + singularOrPlural + ".  Are you sure you want to do this?  It cannot be undone.",
                        messageBoxType: MessageBoxType.warning,
                        middleButtonText: "No",
                        rightButtonText: "Yes",
                        rightButtonAction: () => this.doDeleteSelection(selectedImages)
                    });
            }
        },
        doDeleteSelection(selectedImages) {
            let guids = [];
            for (const index in selectedImages) {
                let image = selectedImages[index];
                image.isDeleting = true;
                guids.push(image.guid);
            }

            this.deleteImagesByGuidsBody = {
                guids: guids
            };

            this.deleteImagesByGuidsTrigger++;
        },
        deleteImagesByGuidsResponse(response) {
            let newImageCollection = this.editItemModel.itemImages.filter(i => i.isDeleting != true);
            this.editItemModel.itemImages.length = 0;
            this.editItemModel.itemImages.push(...newImageCollection);
        },
        newImageUpload() {
            this.$refs.fileInput.click();
        },
        newImageUploaded(event) {
            let files = event.target.files;
            let filename = files[0].name;
            let fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                this.clientImageUrl = fileReader.result;
                let formData = new FormData();
                //formData.append('file', fileReader.result);
                formData.append('file', this.clientImage);
                axios.post('/Image/UploadItemImage?itemId=' + this.itemModel.id, formData)
                    .then(response => {
                        let message = "";
                        switch (response.data.statusCode) {
                            case StatusCodes.NoError: {
                                let itemImageModel = response.data.itemImageModel;
                                this.addItemImage(itemImageModel);
                                break;
                            }

                            case StatusCodes.UploadedFileHasInvalidExtension: {
                                message = "The uploaded file is not of the correct type.  Please choose another one.";
                                break;
                            }

                            default: {
                                message = "An unknown error occurred.  We have been notified and will investigate.";
                            }
                        }

                        if (message != "") {
                            MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                                title: "Attention",
                                body: message,
                                messageBoxType: MessageBoxType.error
                            });
                        }
                    })
                    .catch(error => {
                        this.loggerConfig.submit(LogLevel.Error, "Error in image upload", error);
                    });
            });
            fileReader.readAsDataURL(files[0]);
            this.clientImage = files[0];
        },
        addItemImage(itemImageModel) {
            if (this.editItemModel.itemImages == null) {
                this.editItemModel.itemImages = [];
            }
            this.editItemModel.itemImages.push(itemImageModel);
        },
        validationSuccessful() {
            this.upsertItemBody = this.editItemModel;
            this.upsertItemTrigger++;
        },
        upsertItemResponse(response) {
            if (response?.data?.envelope?.id > 0) {
                this.editItemModel.vendorName = response.data.envelope.vendorName;
                this.editItemModel.itemName = response.data.envelope.name;
                this.editItemModel.id = response.data.envelope.id;
                this.editItemModel.itemTypeName = response.data.envelope.itemTypeName;
                this.editItemModel.checkedOutByContacts = response.data.envelope.checkedOutByContacts;
                this.editItemModel.maxQuantity = response.data.envelope.maxQuantity;
                this.pushEditItemModel();
                this.globalItemEditViewModel.isEditItemVisible = false;
            } else {
                switch (response.data.responseHeader.statusCode) {
                    case StatusCodes.ItemAlreadyExists:
                        MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                            body: "An item with the provided definition already exists.  Please check the values or check the existing record.",
                            title: "Item Data Error",
                            messageBoxType: MessageBoxType.error
                        });
                        break;

                    case StatusCodes.PlanLimitVendorExceeded:
                        MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                            body: "Unfortunately, that cannot be done as it will exceed your plan limit.  Please consider upgrading to increase or eliminate the limits.",
                            title: "Plan Limit Exceeded",
                            messageBoxType: MessageBoxType.error
                        });
                        break;

                    default:
                        MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                            body: "There was an issue with submitting the record.  An error has been logged and we will look into it."
                        });
                        break;
                }
            }
        },
        closeButtonClick() {
            this.globalItemEditViewModel.isEditItemVisible = false;
        },
        pullEditItemModel() {
            this.editItemModel = {
                id: this.itemModel?.id,
                clientId: this.clientId,
                name: this.itemModel?.name,
                vendorId: this.itemModel?.vendorId,
                vendorName: this.itemModel?.vendorName,
                patternName: this.itemModel?.patternName,
                patternNumber: this.itemModel?.patternNumber,
                color: this.itemModel?.color,
                itemTypeId: this.itemModel?.itemTypeId,
                locatorCode: this.itemModel?.locatorCode,
                isDiscontinued: this.itemModel?.isDiscontinued,
                isVendorActive: this.itemModel?.isVendorActive,
                notes: this.itemModel?.notes,
                checkedOutByContacts: this.itemModel?.checkedOutByContacts,
                maxQuantity: this.itemModel?.maxQuantity,
                itemImages: this.itemModel?.itemImages
            };

            this.$emit("update:edit-item-model", this.editItemModel);
        },
        getCheckedOutContactName(checkedOutContact) {
            let daysAgo = "";
            if (checkedOutContact.numberOfDaysOld == 0) {
                daysAgo = "yesterday";
            } else if (checkedOutContact.numberOfDaysOld == 1) {
                daysAgo = checkedOutContact.numberOfDaysOld + " day ago";
            } else if (checkedOutContact.numberOfDaysOld > 1) {
                daysAgo = checkedOutContact.numberOfDaysOld + " days ago";
            }

            return checkedOutContact.name + " (" + daysAgo + ")";
        },
        viewContactInfoCard(checkedOutContact) {


            // Need to put a call in here to pull the contact from
            // the DB and set it in the checkedOutBy


            this.checkedOutBy = checkedOutContact;
            this.checkedOutBy.isVisible = true;
            this.$emit("update:checked-out-contact", this.checkedOutBy);
        },
        pushEditItemModel() {
            this.$emit("update:item-model", this.editItemModel);
        },
        loadVendors() {
            this.getVendorsByClientIdBody = {
                clientId: this.clientId
            };
            this.getVendorsByClientIdTrigger++;
        },
        getVendorsByClientIdResponse(response) {
            let jsSelectItems = response.data.data.filter(d => d.displayName != " ").map(d => { return { text: d.displayName, value: d.id } });
            jsSelectItems.sort((left, right) => left.text.toUpperCase() > right.text.toUpperCase() ? 1 : -1);
            this.vendorListItemString = JSON.stringify(jsSelectItems);
        },
        loadItemTypes() {
            this.getItemTypesByClientIdBody = {
                clientId: this.clientId
            };
            this.getItemTypesByClientIdTrigger++;
        },
        getItemTypesByClientIdResponse(response) {
            let jsSelectItems = response.data.data.map(d => { return { text: d.name, value: d.id } });
            jsSelectItems.sort((left, right) => left.text.toUpperCase() > right.text.toUpperCase() ? 1 : -1);
            this.itemTypesListItemString = JSON.stringify(jsSelectItems);
        },
        addNewVendor(newVendor) {
            this.newContact = newVendor;
            this.upsertContactBody = {
                clientId: this.clientId,
                businessName: this.newContact.text,
                isVendor: true
            };
            this.upsertContactTrigger++;
        },
        upsertContactResponse(response) {
            this.newContact.value = response.data.envelope.id;
            this.newContact.text = response.data.envelope.businessName;
            this.newContact = null;
        },
        addNewItemType(newItemType) {
            this.newItemType = newItemType;
            this.upsertItemTypeBody = {
                clientId: this.clientId,
                name: this.newItemType.text
            };
            this.upsertItemTypeTrigger++;
        },
        upsertItemTypeResponse(response) {
            this.newItemType.value = response.data.envelope.id;
            this.newItemType.text = response.data.envelope.name;
            this.newItemType = null;
        }
    },
    created() {
        this.tabList = [
            {
                label: "Details"
            },
            {
                label: "Notes"
            },
            {
                label: "Images"
            },
            {
                label: "Checkout"
            }
        ];
        this.$data.validationNeeded = false;
        this.$data.validationMessages = [];
        this.pullEditItemModel();
    },
    computed: {
        isEditItemVisible: {
            get() {
                return this.globalItemEditViewModel.isEditItemVisible;
            }
        }
    },
    watch: {
        checkedOutContact(newValue) {
        },
        globalItemEditViewModel: {
            handler(newValue, oldValue) {
                if (this.isEditItemVisible == true) {
                    this.loadVendors();
                    this.loadItemTypes();
                }
            },
            deep: true
        },
        itemModel(newValue, oldValue) {
            this.pullEditItemModel();
        }
    },
    template: "#ItemEditModal"
};

export default ItemEditModal;

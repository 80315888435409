const AccountSignUp = {
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        userModel: null,
        loadTrigger: 0
    },
    data: () => ({
    }),
    template: "#AccountSignUp"
}

export default AccountSignUp

const CookiePolicyPrompt = {
    props: {
        cookieAnswer: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        bannerStyle: null
    }),
    methods: {
        confirmCookiePolicy: function () {
            axios({
                method: 'get',
                url: '/Home/RecordAnswerToCookiePolicy?wasAccepted=true',
                data: {
                    wasAccepted: true
                }
            })
                .then(response => {
                    if (response.status == 200) {
                        this.setBannerStyle(true);
                    }
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                });
        },
        setBannerStyle: function (isHidden) {
            this.bannerStyle = isHidden
                ? "display:none"
                : "display:block";
        }
    },
    created() {
        this.setBannerStyle(this.cookieAnswer);
    },
    template: "#cookiePolicyPrompt"
};

export default CookiePolicyPrompt;

const ContactInfoCard = {
    emits: ["update:checked-out-contact"],
    props: {
        checkedOutContact: null,
        stateList: null
    },
    data: () => ({
    }),
    methods: {
        closeButtonClick() {
            this.$emit("update:checked-out-contact", null);
        }
    },
    computed: {
        businessName: {
            get() {
                return this.checkedOutContact?.businessName;
            }
        },
        address1: {
            get() {
                return this.checkedOutContact?.address1;
            }
        },
        firstName: {
            get() {
                return this.checkedOutContact?.firstName;
            }
        },
        address2: {
            get() {
                return this.checkedOutContact?.address2;
            }
        },
        lastName: {
            get() {
                return this.checkedOutContact?.lastName;
            }
        },
        city: {
            get() {
                return this.checkedOutContact?.city;
            }
        },
        phone: {
            get() {
                return this.checkedOutContact?.phone;
            }
        },
        state: {
            get() {
                let s = this.stateList.filter(st => st.value == this.checkedOutContact?.stateId);
                return s.length > 0
                    ? s[0].text
                    : "";
            }
        },
        mobile: {
            get() {
                return this.checkedOutContact?.mobile;
            }
        },
        zip: {
            get() {
                return this.checkedOutContact?.zip;
            }
        },
        fax: {
            get() {
                return this.checkedOutContact?.fax;
            }
        },
        zipPlus4: {
            get() {
                return this.checkedOutContact?.zipPlus4;
            }
        },
        email: {
            get() {
                return this.checkedOutContact?.email;
            }
        },
        website: {
            get() {
                return this.checkedOutContact?.website;
            }
        }
    },
    template: "#ContactInfoCard"
};

export default ContactInfoCard;

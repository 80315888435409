// If you change the content of this file, it must be reflected in
// navBarElementType.cs and the [dbo].[NavBarEntryTypeOfElement] table.

const NavBarElementType = {
    ClientNameDropdown: 1,
    ClientNameLabel: 2,
    Divider: 3,
    Dropdown: 4,
    DropdownItem: 5,
    DropdownSub: 6,
    Label: 7,
    TopButton: 8,
    TopLevel: 9,
    FunctionalArea: 10,
    ClientSelector: 11
}

export { NavBarElementType }

<template>
</template>
<script>
    export default {
        emits: ["api-response", "api-error"],
        props: {
            url: null,
            apiBody: null,
            apiTrigger: 0,
            verb: { type: String, default: "POST" },
            doesNotTriggerSpinner: null
        },
        watch: {
            apiTrigger(newValue, oldValue) {
                console.log(`Attempting to initiate ${this.verb} to ${this.url}, sending body of:`, this.apiBody);
                if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                    window.dispatchEvent(new CustomEvent("addAjaxCall"));
                }

                axios({
                    method: this.verb,
                    url: this.url,
                    data: JSON.stringify(this.apiBody),
                    headers: {
                        "content-type": "application/json"
                    }
                })
                    .then(response => {
                        if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                            window.dispatchEvent(new CustomEvent("removeAjaxCall"));
                        }
                        console.log("RESPONSE", response);
                        this.$emit("api-response", response);
                    })
                    .catch(error => {
                        if (this.doesNotTriggerSpinner != "true" && this.doesNotTriggerSpinner != true) {
                            window.dispatchEvent(new CustomEvent("removeAjaxCall"));
                        }
                        console.log("ERROR", error);
                        this.$emit("api-error", error);
                    });
            }
        },
        name: "v_api"
    };
</script>

<template>
    <div class="form-control-group form-outline" v-click-outside="() => clickedOutside()">
        <button class="yds-dropdown form-control-input form-control yds-text-align-left"
                :id="getId"
                v-bind="$attrs"
                @change="onChange($event)"
                :readonly="readonly"
                :disabled="readonly"
                :tabindex="tabindex"
                ref="dropdownButton"
                v-on:click="dropdownToggle"
                v-on:keydown.tab="dropdownButtonTabDownKey($event)"
                v-on:keydown.page-down="dropdownItemDownKey(5, $event)"
                v-on:keydown.page-up="dropdownItemUpKey(5, $event)"
                v-on:keydown.esc="dropdownItemEscKey($event)"
                v-on:keydown.enter="dropdownItemEnterKey($event)"
                v-on:keydown.down="dropdownItemDownKey(1, $event)"
                v-on:keydown.up="dropdownItemUpKey(1, $event)">
            {{__selectedValue}}
            <span class="yds-dropdown-select-arrow">&nbsp;</span>
        </button>
        <div :class="'yds-dropdown-search-container ' + (__show ? ' yds-dropdown-show' : '')">
            <input class="yds-dropdown-search-field"
                   v-model="__searchFilter"
                   type="text"
                   placeholder="Search.."
                   ref="searchBox"
                   v-on:keydown.tab="searchBoxTabPressed($event)"
                   v-on:keyup.esc="dropdownItemEscKey($event)" />
        </div>
        <div :class="'yds-dropdown-content ' + (__show ? ' yds-dropdown-show' : '')"
             ref="dropdownContent"
             v-on:wheel="mouseWheel($event)">
            <div v-show="__items.length == 0">
                <a class="yds-cursor-default"
                   v-on:click="onChange(-1)">
                    {{getNoSearchEntryLabel()}}
                </a>
            </div>
            <div>
                <a :class="'yds-cursor-default ' + (__hotItemIndex == index ? 'yds-dropdown-item-hot' : '')"
                   v-for="(item, index) in __items"
                   ref="__items"
                   v-on:click="onChange(item)">
                    {{item.text}}
                </a>
            </div>
        </div>
        <button class="yds-dropdown-content-handler"
                ref="dropdownContentHandler"
                v-on:keydown.tab="dropdownContentHandlerTabPressed($event)"
                v-on:keydown.page-down="dropdownItemDownKey(5, $event)"
                v-on:keydown.page-up="dropdownItemUpKey(5, $event)"
                v-on:keydown.esc="dropdownItemEscKey($event)"
                v-on:keydown.enter="dropdownItemEnterKey($event)"
                v-on:keydown.down="dropdownItemDownKey(1, $event)"
                v-on:keydown.up="dropdownItemUpKey(1, $event)"
                v-on:keydown="hotListKeySelector($event)"></button>
        <label class="yds-color-secondary form-control-label-inside form-label"
               :for="getId">
            {{label}}
            <span v-if="__isRequired" class="yds-required-field">*</span>
        </label>
    </div>
</template>
<script>
    import { guidHelper, guidHelperFormats } from '/wwwroot/js/common/guidHelper.js'

    export default {
        emits: ["update:model-value", "no-search-entry-click"],
        props: {
            modelValue: null,
            id: null,
            itemsString: null,
            items: null,
            required: false,
            label: null,
            defaultLabel: "-- SELECT ONE --",
            readonly: false,
            tabindex: 0,
            allowSearch: true,
            textForNoSearchEntry: null
        },
        data: () => ({
            __id: null,
            __isRequired: null,
            __items: [],
            __origItems: [],
            __show: false,
            __selectedValue: "",
            __searchFilter: "",
            __selectedItemIndex: -1,
            __newItem: {
                value: null,
                text: null
            },
            __createNewItem: 0,
            __hotItemIndex: -1,
            __lastCharacterPressed: null,
            __lastCharacterIdentifiedItemFound: -1,
            __numberOfWarningFlashesRemaining: 3,
            __numberOfWarningFlashesTotal: 3,
            __hotFlashDuration: 75,
            __hotFlashDelay: 75,
            __hotFlashIndex: 0
        }),
        methods: {
            mouseWheel(event) {
                event.preventDefault();
                if (event.wheelDelta > 0) {
                    // Scroll up
                    this.__hotItemIndex--;
                    if (this.__hotItemIndex < 0) {
                        this.__hotItemIndex = 0;
                    }
                    this.scrollHotIntoView();
                } else {
                    // Scroll down
                    this.__hotItemIndex++;
                    if (this.__hotItemIndex > this.__items.length - 1) {
                        this.__hotItemIndex = this.__items.length - 1;
                    }
                    this.scrollHotIntoView();
                }
            },
            hotListKeySelector(event) {
                if (["Shift", "Control", "Alt", "Escape", "Tab", "ArrowUp", "ArrowDown", "PageDown", "PageUp"].includes(event.key)) {
                    return;
                }
                if (this.__lastCharacterPressed == null) {
                    // Get first in the list.
                    for (let index in this.__items) {
                        let item = this.__items[index];
                        let text = item.text;
                        if (text?.length > 0) {
                            if (text[0].toUpperCase() == event.key.toUpperCase()) {
                                this.__lastCharacterPressed = event.key;
                                this.__lastCharacterIdentifiedItemFound = parseInt(index);
                                this.__hotItemIndex = index;
                                this.scrollHotIntoView();
                                return;
                            }
                        }
                    }
                    this.beginFlashHot();
                } else {
                    // Get the next in the list.
                    for (let index = this.__lastCharacterIdentifiedItemFound + 1; index < this.__items.length; index++) {
                        let item = this.__items[index];
                        let text = item.text;
                        if (text?.length > 0) {
                            if (text[0].toUpperCase() == event.key.toUpperCase()) {
                                this.__lastCharacterPressed = event.key;
                                this.__lastCharacterIdentifiedItemFound = parseInt(index);
                                this.__hotItemIndex = index;
                                this.scrollHotIntoView();
                                return;
                            }
                        }
                    }

                    this.__lastCharacterPressed = null;
                    this.__lastCharacterIdentifiedItemFound = -1;
                    this.hotListKeySelector(event);
                }
            },
            beginFlashHot() {
                this.__numberOfWarningFlashesRemaining = this.__numberOfWarningFlashesTotal;
                this.__hotFlashIndex = this.__hotItemIndex;
                this.hotFlashOn();
            },
            hotFlashOn() {
                this.$refs.__items[this.__hotItemIndex].classList.add("yds-dropdown-item-hot-warn");
                setTimeout(() => this.hotFlashOff(), this.__hotFlashDuration);
            },
            hotFlashOff() {
                this.$refs.__items[this.__hotItemIndex].classList.remove("yds-dropdown-item-hot-warn");
                this.__numberOfWarningFlashesRemaining--;
                if (this.__numberOfWarningFlashesRemaining > 0) {
                    setTimeout(() => this.hotFlashOn(), this.__hotFlashDelay);
                }
            },
            dropdownButtonTabDownKey(event) {
                this.hidePanel();
            },
            dropdownContentHandlerShiftTabPressed(event) {
                event.preventDefault();
                this.$nextTick(() => this.$refs.searchBox.focus());
            },
            dropdownContentHandlerTabPressed(event) {
                if (event.shiftKey) {
                    this.dropdownContentHandlerShiftTabPressed(event);
                    return;
                }
                this.hidePanel();
                this.$refs.dropdownButton.focus();
            },
            searchBoxTabPressed(event) {
                if (event.shiftKey) {
                    this.searchBoxShiftTabPressed(event);
                    return;
                }
                event.preventDefault();
                this.$nextTick(() => this.$refs.dropdownContentHandler.focus());
            },
            searchBoxShiftTabPressed($event) {
                event.preventDefault();
                this.hidePanel();
                this.$nextTick(() => this.$refs.dropdownButton.focus());
            },
            dropdownItemTabKey() {
                this.hidePanel();
            },
            showPanel() {
                this.__lastCharacterPressed = null;
                this.__lastCharacterIdentifiedItemFound = -1;
                this.__show = true;
                this.scrollHotIntoView();
                this.$nextTick(() => this.$refs.searchBox.focus());
            },
            hidePanel() {
                this.__show = false;
            },
            dropdownItemEscKey(event) {
                this.hidePanel();
                this.$nextTick(() => this.$refs.dropdownButton.focus());
            },
            dropdownItemDownKey(amount) {
                if (this.__show == false) {
                    this.showPanel();
                    this.__hotItemIndex = this.__selectedItemIndex;
                } else {
                    this.__hotItemIndex += amount;
                }

                if (this.__hotItemIndex > this.__items.length - 1) {
                    this.__hotItemIndex = this.__items.length - 1;
                }

                this.scrollHotIntoView();
            },
            dropdownItemUpKey(amount) {
                this.__hotItemIndex -= amount;
                if (this.__hotItemIndex < 0) {
                    this.__hotItemIndex = 0;
                }
                this.scrollHotIntoView();
            },
            dropdownItemEnterKey(event) {
                if (this.__show == false) {
                    this.showPanel();
                    this.__hotItemIndex = this.__selectedItemIndex;
                } else {
                    let item = this.__items[this.__hotItemIndex];
                    this.onChange(item, event);
                }
            },
            scrollHotIntoView() {
                let scrolledIndex;
                if (this.__hotItemIndex > 1) {
                    scrolledIndex = this.__hotItemIndex - 2;
                } else {
                    scrolledIndex = 0;
                }

                this.$nextTick(() => {
                    this.$refs.__items[scrolledIndex]?.scrollIntoView({ behavior: 'smooth' });
                });
            },
            getNoSearchEntryLabel() {
                return this.textForNoSearchEntry?.replace("{text}", this.__searchFilter);
            },
            dropdownToggle() {
                if (this.__show) {
                    this.hidePanel();
                } else {
                    this.showPanel();
                }
            },
            onChange(item, event) {
                if (item == -1) {
                    this.__newItem.text = this.__searchFilter;
                    this.$emit("no-search-entry-click", this.__newItem);
                } else {
                    let newValue = item.value;
                    this.__selectedValue = item.text;
                    this.hidePanel();
                    this.$emit("update:model-value", newValue);
                    if (event != null) {
                        event.preventDefault();
                    }
                    this.$nextTick(() => this.$refs.dropdownButton.focus());
                }
            },
            pullItemsString() {
                let jsonStr = this.itemsString.replaceAll("^\"|\"$", "");
                let json = JSON.parse(jsonStr);
                this.__origItems.length = 0;
                this.__origItems.push(...json);
                this.__items.length = 0;
                this.__items.push(...json);
                this.getFirstModelValue();
            },
            getFirstModelValue() {
                if (this.modelValue == 0 && this.__items.length > 0) {
                    let firstItem = this.__items[0];
                    this.$emit("update:model-value", firstItem.value);
                    this.__selectedValue = firstItem.text;
                }
                if (this.modelValue != undefined) {
                    let item = this.__items.filter(i => i.value == this.modelValue);
                    if (item.length == 0) {
                        return;
                    }
                    this.__selectedValue = item[0].text;
                }
                this.updateIndexes();
            },
            clickedOutside() {
                if (!this.__show) {
                    return;
                }
                this.hidePanel();
            },
            applySearchFilter() {
                let filtered = this.__origItems.filter(i => i.text.toUpperCase().indexOf(this.__searchFilter.toUpperCase()) != -1);
                this.__items.length = 0;
                this.__items.push(...filtered);
                this.updateIndexes();
                this.__hotItemIndex = 0;
                this.scrollHotIntoView();
            },
            updateIndexes() {
                for (let i = 0; i < this.__items.length; i++) {
                    let item = this.__items[i];
                    item.index = i;
                    if (item.value == this.modelValue) {
                        this.__selectedItemIndex = i;
                        this.__hotItemIndex = i;
                    }
                }
            }
        },
        computed: {
            getId: {
                get: function () {
                    this.__isRequired = this.required !== undefined;
                    if (this.__id === null) {
                        this.__id = guidHelper.getNewGuid("INPUT_", guidHelperFormats.short);
                    }
                    return this.__id;
                }
            },
        },
        watch: {
            itemsString(newValue, oldValue) {
                console.log("watch response from itemsString");
                this.pullItemsString();
            },
            modelValue(newValue, oldValue) {
                //console.log("watch response from modelValue");
                //if (this.modelValue == null) {
                //    this.modelValue = 0;
                //}
                this.getFirstModelValue();
            },
            __searchFilter(newValue, oldValue) {
                console.log("watch response from __searchFilter");
                this.applySearchFilter();
            },
            __newItem: {
                handler(newValue, oldValue) {
                    console.log("watch response from __newItem");
                    if (this.__ignoreNewItem) {
                        this.__ignoreNewItem = false;
                    } else {
                        this.__ignoreNewItem = true;
                        this.__origItems.push(this.__newItem);
                        this.onChange(this.__newItem);
                        this.applySearchFilter();
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.items != null) {
                this.__origItems.push(...this.items);
                this.__items.push(...this.items);
            } else if (this.itemsString?.length > 0) {
                this.pullItemsString();
            }
            this.getFirstModelValue();
        },
        mounted() {
        },
        beforeDestroy() {
        },
        name: "v_dropdown"
    };
</script>
const CheckForInvitations = {
    props: {
        invitations: Array
    },
    data: () => ({
        acceptTrigger: -1,
        apiTrigger: 0,
        noInvitationsVisible: false
    }),
    methods: {
        acceptResponse(response) {
            let invitationId = this.acceptTrigger;
            this.acceptTrigger = -1;

            let invitation = this.invitations.filter(i => i.id == invitationId)[0];
            invitation.isVisible = false;

            // !!! I don't like how this is handled but I'll allow it because I do have
            // !!! to rewrite how the signup process is handled w/ a payment processor
            // !!! so this might be affected.  Right now, it'll reload and activate
            // !!! the chosen invitation but what about additional ones?  Currently, there
            // !!! is no way to view other pending invites.  That should be made part of
            // !!! an account management.

            window.location.href = "/Dashboard";
        //    let numVisibleInvitations = this.invitations.filter(i => i.isVisible).length;
        //    this.noInvitationsVisible = numVisibleInvitations == 0;
        },
        acceptError(error) {
            console.log("ERROR", error);
        }
    },
    watch: {
        acceptTrigger(newValue, oldValue) {
            console.log("watch response from acceptTrigger");
            if (newValue == -1) {
                return;
            }

            this.apiTrigger++;
        }
    },
    template: "#CheckForInvitations"
};

export default CheckForInvitations;

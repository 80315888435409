const SignUpOnMyOwn = {
    props: {
    },
    data: () => ({
        signUpType: 0
    }),
    watch: {
        signUpType(newValue, oldValue) {
            console.log("watch response from signUpType");
            window.location.href = "/SignUp?signUpType=" + newValue;
        }
    },
    template: "#SignUpOnMyOwn"
};

export default SignUpOnMyOwn;

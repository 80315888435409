import VisibilityHelper from "/wwwroot/js/common/visibilityHelper.js";

const NavBarElementDropdownSub = {
    props: {
        modelValue: null,
        globalConfigViewModel: null
    },
    methods: {
        getVisibilityOfAllChildren(model) {
            return VisibilityHelper.getVisibilityOfAllChildren(model);
        }
    },
    template: "#NavBarElementDropdownSub"
}

export default NavBarElementDropdownSub

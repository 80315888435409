const NavBarElementLabel = {
    props: {
        modelValue: null,
        globalConfigViewModel: null
    },
    mounted() {
        console.log("NavBarElementLabel");
    },
    template: "#NavBarElementLabel"
}

export default NavBarElementLabel

const SignUpCard = {
    emits: ["update:sign-up-type"],
    props: {
        id: null,
        planName: null,
        numUsers: null,
        numVendors: null,
        numContacts: null,
        numItems: null,
        numLocations: null,
        costPerMonth: null,
        costPerYear: null,
        savingsPerYear: null,
        isVisible: null,
        displayOrder: null,
        publishableKey: null
    },
    data: () => ({
        signUpTrigger: 0
    }),
    computed: {
        costPerMonthDisplay: {
            get() {
                return this.costPerMonth == 0
                    ? "- FREE -"
                    : `${this.costPerMonth} / mo or ${this.costPerYear} / yr`;
            }
        }
    },
    watch: {
        signUpTrigger(newValue, oldValue) {
            console.log("watch response from signUpTrigger");
            this.$emit("update:sign-up-type", this.id);
        }
    },
    template: "#SignUpCard"
};

export default SignUpCard;

<template>
    <div>
        <input type="checkbox" 
               v-bind="$attrs"
               v-model="checkedValue"
               @change="updateInput"
               :tabindex="tabindex"/>
    </div>
</template>
<script>
    export default {
        emits: ["update:model-value", "change"],
        props: {
            modelValue: false,
            tabindex: 0
        },
        data: () => ({
        }),
        methods: {
            updateInput() {
                this.$emit("update:model-value", this.checkedValue);
            }
        },
        computed: {
            checkedValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("change", value);
                    this.$emit("update:model-value", value);
                }
            }
        },
        name: "v_checkbox"
    };
</script>
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";
import { FileDownloadHelper } from "/wwwroot/js/common/fileDownloadHelper.js";

const NavBarElementDropdownItem = {
    props: {
        modelValue: null,
        globalConfigViewModel: null
    },
    data: () => ({
        isDownloadLink: false,
        downloadLink: null,
        downloadLinkTrigger: 0
    }),
    methods: {
        downloadLinkResponse(response) {
            if (response.status == 200) {
                if (response.data.data.downloadLink == '' || response.data.data.reportName == '') {
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        body: "No records were found to generate a document."
                    });
                } else {
                    FileDownloadHelper.downloadFile(response.data.data.downloadLink, response.data.data.reportName);
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        body: "Please check your Downloads folder for the result of that link."
                    });
                }
            } else {
                MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                    body: "There was an issue with that link.  We have been notified and will investigate.",
                    title: "Attention",
                    messageBoxType: MessageBoxType.error
                });
            }
        },
        click() {
            console.log("CLICK", this.modelValue.href);
            if (this.isDownloadLink) {
                this.downloadLinkTrigger++;
            } else {
                window.location.href = this.modelValue.href;
            }
        },
        getUrl() {
            return this.downloadLink;
        }
    },
    mounted() {
        let firstThree = this.modelValue.href.slice(0, 3);
        if (firstThree == "DL:") {
            this.isDownloadLink = true;
            this.downloadLink = this.modelValue.href.slice(3);
        }
    },
    template: "#NavBarElementDropdownItem"
}

export default NavBarElementDropdownItem

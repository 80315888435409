﻿const FileDownloadHelper = {
    downloadFile(url, name, type) {
        if (type == null) {
            type = "pdf";
        }
        axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/' + type })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error);
    }
}

export { FileDownloadHelper }

<template>
    <div class="yds-pagination-container">

        <!-- Jumps -->

        <button :class="'yds-pagination-left' + getPaginationSize()" v-if="pageNumber > 0" type="button" @click="jumpTo(0)">&lt;&lt;</button>
        <button :class="'yds-pagination-left-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber > 0" type="button" @click="jumpTo(pageNumber - 1)">&lt;</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- 0 -->

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber > 1" type="button" @click="jumpTo(pageNumber - 2)">{{pageNumber - 1}}</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- 1 -->

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber > 0" type="button" @click="jumpTo(pageNumber - 1)">{{pageNumber}}</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- 2 -->

        <button :class="'yds-pagination-mid-selected' + getPaginationSize()" v-if="numberOfPages > 0" type="button" @click="jumpTo(pageNumber)">{{pageNumber + 1}}</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- 3 -->

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber < (numberOfPages - 1)" type="button" @click="jumpTo(pageNumber + 1)">{{pageNumber + 2}}</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- 4 -->

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber < (numberOfPages - 2)" type="button" @click="jumpTo(pageNumber + 2)">{{pageNumber + 3}}</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <!-- Jumps -->

        <button :class="'yds-pagination-mid' + getPaginationSize()" v-if="pageNumber < (numberOfPages - 1)" type="button" @click="jumpTo(pageNumber + 1)">&gt;</button>
        <button :class="'yds-pagination-mid-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>

        <button :class="'yds-pagination-right' + getPaginationSize()" v-if="pageNumber < (numberOfPages - 1)" type="button" @click="jumpTo(-1)">&gt;&gt;</button>
        <button :class="'yds-pagination-right-empty' + getPaginationSize()" v-else type="button">&nbsp;</button>
    </div>
</template>
<script>
    export default {
        emits: ["update:pageNumber", "before-page-jump", "after-page-jump"],
        props: {
            numberOfItems: 0,
            pageNumber: 0,
            itemsPerPage: 0,
            numberOfPages: 0
        },
        methods: {
            jumpTo: function (newPageNumber) {
                if (newPageNumber == -1) {
                    newPageNumber = this.numberOfPages - 1;
                }
                if (newPageNumber != this.pageNumber) {
                    let fromPage = this.pageNumber;
                    this.$emit("before-page-jump", fromPage, newPageNumber);
                    this.$emit("update:pageNumber", newPageNumber);
                    this.$emit("after-page-jump", fromPage, newPageNumber);
                }
            },
            getPaginationSize() {
                if (this.pageNumber < 97) {
                    return "-sm";
                } else if (this.pageNumber < 9997) {
                    return "-lg";
                } else {
                    return "-xl";
                }
            }
        },
        watch: {
        },
        name: "v_grid_pagination"
    }
</script>
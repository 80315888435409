import { MessageBoxType } from "/wwwroot/js/common/messageBox.js";
import ValidationMixin from "/wwwroot/js/vue/validationMixin.js";

const MessageBoxPopup = {
    mixins: [ValidationMixin],
    props: {
        messageBoxModel: null
    },
    data: () => ({
        inputText: null,
        iconClass: null,
        validationNeeded: false,
        validationMessages: [],
        callingButton: null,
        clearValidationTrigger: 0
    }),
    methods: {
        getClass() {
            let classPrefix = "fa-solid fs-1 ";
            switch (this.messageBoxModel.messageBoxType) {
                case MessageBoxType.question:
                    return classPrefix + "fa-question-circle yds-icon-blue";

                case MessageBoxType.warning:
                    return classPrefix + "fa-exclamation-triangle yds-icon-yellow";

                case MessageBoxType.error:
                    return classPrefix + "fa-circle-exclamation yds-icon-red";

                default: // information or unknown
                    return classPrefix + "fa-circle-info yds-icon-blue";
            }
        },
        getInputLabel() {
            return this.messageBoxModel?.inputLabel ?? "";
        },
        getRightButtonText() {
            let rightButtonText = this.messageBoxModel?.rightButtonText ?? "Ok";
            return rightButtonText;
        },
        leftButtonClick() {
            this.callingButton = "leftButtonAction";
            if (this.messageBoxModel.messageBoxType == MessageBoxType.input &&
                this.messageBoxModel.inputIsRequired == true &&
                this.messageBoxModel.leftButtonTriggersValidation == true) {
                this.setValidationNeeded();
            } else {
                this.handleFinalCallback();
            }
        },
        middleButtonClick() {
            this.callingButton = "middleButtonAction";
            if (this.messageBoxModel.messageBoxType == MessageBoxType.input &&
                this.messageBoxModel.inputIsRequired == true &&
                this.messageBoxModel.middleButtonTriggersValidation == true) {
                this.setValidationNeeded();
            } else {
                this.handleFinalCallback();
            }
        },
        rightButtonClick() {
            this.callingButton = "rightButtonAction";
            if (this.messageBoxModel.messageBoxType == MessageBoxType.input &&
                this.messageBoxModel.inputIsRequired == true &&
                this.messageBoxModel.rightButtonTriggersValidation == true) {
                this.setValidationNeeded();
            } else {
                this.handleFinalCallback();
            }
        },
        closeClick() {
            this.callingButton = "closeAction";
            if (this.messageBoxModel.messageBoxType == MessageBoxType.input &&
                this.messageBoxModel.inputIsRequired == true &&
                this.messageBoxModel.closeButtonTriggersValidation == true) {
                this.setValidationNeeded();
            } else {
                this.handleFinalCallback();
            }
        },
        handleFinalCallback() {
            let callbackName = this.callingButton;
            this.messageBoxModel.isVisible = false;
            if (typeof (this.messageBoxModel[callbackName]) == 'function') {
                this.messageBoxModel[callbackName](this.inputText);
            }
        },
        validationSuccessful() {
            this.handleFinalCallback();
        },
        isValidationActive() {
            return inputIsRequired;
        }
    },
    watch: {
        messageBoxModel: {
            handler(newValue, oldValue) {
                if (this.messageBoxModel.isVisible == true) {
                    this.clearValidationTrigger++;
                    this.inputText = this.messageBoxModel.inputText;
                }
            },
            deep: true
        }
    },
    mounted() {
    },
    template: "#MessageBoxPopup"
};

export default MessageBoxPopup;

<template>
    <div class="yds-grid-row-selector-container">
        Rows per page:
        <select v-model="itemsPerPage">
            <option v-for="row in rowsPerPage" v-bind:value="row.id">
                {{row.name}}
            </option>
        </select>
    </div>
</template>
<script>
    export default {
        emits: ["update:itemsPerPage"],
        data: () => ({
            itemsPerPage: '10',
            rowsPerPage: [
                { id: 10, name: "10" },
                { id: 25, name: "25" },
                { id: 50, name: "50" },
                { id: 100, name: "100" },
                { id: 999, name: "All" }
            ]
        }),
        watch: {
            itemsPerPage(newValue, oldValue) {
                console.log("itemsPerPage", this.itemsPerPage);
                this.$emit("update:itemsPerPage", newValue);
            }
        },
        name: "v_grid_row_selector"
    }
</script>

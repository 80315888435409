<template>
    <div>
        <nav>
            <div class="nav yds-nav-tabs" role="tablist">
                <div v-for="(tabEntry, index) in modelValue">
                    <button v-if="tabEntry.visible == true || tabEntry.visible == undefined" 
                            :class="getNavLinkClass(index, tabEntry)" 
                            v-on:click="tabClick(tabEntry, index)">
                        {{tabEntry.label}}
                    </button>
                </div>
            </div>
        </nav>
        <div v-for="(tabEntry, index) in modelValue"
             :class="'yds-tab-content-container ' + tabEntry.class"
             role="tabpanel"
             v-show="tabIndex == index">
            <slot :name="'panel-' + index">
            </slot>
        </div>
    </div>
</template>
<script>
    export default {
        emits: ["update:tab-index"],
        name: "v_tablist",
        props: {
            /*
            modelValue: {
                tabs: [
                    {
                        id: "#panelId,
                        label: "Tab Label",
                        class: "value to add to class def"
                    }, ...
                ]
             }
             */
            modelValue: null,
            tabIndex: -1
        },
        data: () => ({
        }),
        methods: {
            tabClick(tabEntry, index) {
                if (this.callBeforeChange(tabEntry)) {
                    this.$emit("update:tab-index", index);
                    this.callAfterChange(tabEntry);
                }
            },
            callBeforeChange(tabEntry) {
                let result = true;
                if (tabEntry.beforeChange != undefined || typeof tabEntry.beforeChange === 'function') {
                    result = tabEntry.beforeChange();
                    if (result == undefined || result == null) {
                        result = true;
                    }
                }
                return result;
            },
            callAfterChange(tabEntry) {
                let result = true;
                if (tabEntry.afterChange != undefined || typeof tabEntry.afterChange === 'function') {
                    result = tabEntry.afterChange();
                    if (result == undefined || result == null) {
                        result = true;
                    }
                }
                return result;
            },
            getNavLinkClass(index, tabEntry) {
                return 'yds-nav-link ' + tabEntry.class + (this.tabIndex == index ? ' active' : '');
            }
        }
    }
</script>
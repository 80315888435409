const AccountPreferences = {
    emits: ["update:user-model"],
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        userModel: null,
        loadTrigger: 0
    },
    data: () => ({
        firstName: null,
        lastName: null,
        preferencesUpdateTrigger: 0,
        preferencesUpdateBody: null
    }),
    methods: {
        preferencesUpdateClick() {
            this.preferencesUpdateBody = {
                firstName: this.firstName,
                lastName: this.lastName
            };
            this.preferencesUpdateTrigger++;
        },
        preferencesUpdateResponse(response) {
            this.userModel.firstName = response.data.firstName;
            this.userModel.lastName = response.data.lastName;
            this.$emit("update:user-model", this.userModel);
        },
        pullFromModel() {
            this.firstName = this.userModel.firstName;
            this.lastName = this.userModel.lastName;
        },
        cancelClick() {
            this.pullFromModel();
        }
    },
    watch: {
        loadTrigger(newValue, oldValue) {
            console.log("watch response from loadTrigger");
            this.pullFromModel();
        }
    },
    mounted() {
        this.pullFromModel();
    },
    template: "#AccountPreferences"
}

export default AccountPreferences

<template id="ContactHistory">
    <div>
        <v-grid :column-names="gridColumns"
                :rows="gridRows"
                filter-on-all-fields="true">
            <template v-slot:row="{row, rowNum}">
                <td>{{row.displayName}}</td>
                <td>{{row.itemName}}</td>
                <td>{{row.patternName}}</td>
                <td>{{row.patternNumber}}</td>
                <td>{{row.itemTypeName}}</td>
                <td>{{row.color}}</td>
                <td>{{formatDate(row.outOnUtc)}}<br />{{formatDate(row.inOnUtc)}}</td>
                <td>{{row.numDays}}</td>
            </template>
        </v-grid>
        <v-api url="Checkout/GetCheckoutHistory"
               :api-trigger="apiTrigger"
               :api-body="apiBody"
               v-on:api-response="getCheckoutHistoryResponse"
               v-on:api-error="getCheckoutHistoryError">
        </v-api>
    </div>
</template>
<script>
    export default {
        props: {
            globalConfigViewModel: null,
            messageBoxModel: null,
            editContactModel: null,
            timeZoneId: 2,
            recordIsReadOnly: false,
            loadTrigger: 0
        },
        data: () => ({
            apiTrigger: 0,
            apiBody: null,
            gridColumns: [
                {
                    name: "Vendor",
                    columnName: "displayName"
                },
                {
                    name: "Item Name",
                    columnName: "itemName"
                },
                {
                    name: "Pattern Name",
                    columnName: "patternName"
                },
                {
                    name: "PatternNumber",
                    columnName: "patternNumber"
                },
                {
                    name: "Item Type",
                    columnName: "itemTypeName"
                },
                {
                    name: "Color",
                    columnName: "color"
                },
                {
                    name: ""
                },
                {
                    name: "# Days"
                }
            ],
            gridRows: []
        }),
        methods: {
            getCheckoutHistoryResponse(response) {
                console.log(response);
                        this.gridRows.length = 0;
                        this.gridRows.push(...response.data.data);
            },
            getCheckoutHistoryError(error) {
                console.log("ERROR", error);
            },
            formatDate(value) {
                let tz;
                switch (this.timeZoneId) {
                    case 1:
                        tz = "America/New_York";
                        break;

                    case 3:
                        tz = "America/Denver";
                        break;

                    case 4:
                        tz = "America/Los_Angeles";
                        break;

                    default:
                        tz = "America/Chicago";
                        break;
                }

                let options = { timeZone: tz };
                let date = new Date(value);
                return date.toLocaleDateString("en-US", options);
            },
            pullHistory() {
                this.apiBody = {
                    ContactId: this.editContactModel.id
                };
                this.apiTrigger++;
            }
        },
        watch: {
            loadTrigger(newValue, oldValue) {
                console.log("watch response from loadTrigger");
                this.pullHistory();
            },
            globalContactEditViewModel: {
                handler(newValue, oldValue) {
                    console.log("watch response from globalContactEditViewModel");
                    if (this.globalContactEditViewModel.isEditContactVisible == true &&
                        this.globalItemEditViewModel.isEditItemVisible != true) {
                        this.pullHistory();
                    }
                },
                deep: true
            },
            editContactModel(newValue, oldValue) {
                console.log("watch response from editContactModel");
                this.pullHistory();
            }
        },
        template: "#ContactHistory"
    };
</script>
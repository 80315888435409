const AjaxSpinner = {
    data: () => ({
        numberOfAjaxCalls: 0
    }),
    methods: {
        addAjaxCall() {
            this.numberOfAjaxCalls++;
        },
        removeAjaxCall() {
            this.numberOfAjaxCalls--;
            if (this.numberOfAjaxCalls < 0) {
                this.numberOfAjaxCalls = 0;
            }
        }
    },
    created() {
        window.addEventListener("addAjaxCall", () => this.addAjaxCall());
        window.addEventListener("removeAjaxCall", () => this.removeAjaxCall());
    },
    unmounted() {
        window.removeEventListener("addAjaxCall", () => this.addAjaxCall());
        window.removeEventListener("removeAjaxCall", () => this.removeAjaxCall());
    },
    template: "#AjaxSpinner"
};

export default AjaxSpinner;

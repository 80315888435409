<template>
    <div>
        <slot name="title"></slot>
        <div v-if="hasFilter" class="row">
            <div class="col-12 yds-cell-right">
                <v-textbox v-model="filterText"
                           label-size="small"
                           label="Filter"
                           has-clear="true"
                           help-topic="H0002"></v-textbox>
            </div>
        </div>
        <table width="100%">
            <thead>
                <tr>
                    <th v-for="(headerColumn, index) in localColumnNames" :key="headerColumn.name" :width="headerColumn.width != 0 ? headerColumn.width : undefined" class="yds-grid-column-header">
                        <slot name="header-column"
                              :header-column="headerColumn"
                              :header-column-index="index"
                              :sort-direction="sortDirections[index]"
                              :trigger-sort="triggerSort">

                            <strong v-if="headerColumn.canSort == false">
                                {{headerColumn.name}}
                            </strong>
                            <button v-if="headerColumn.name != null && (headerColumn.canSort == undefined || headerColumn.canSort == true)" type="button" class="sort-button" v-on:click="triggerSort(index)">
                                <strong>{{headerColumn.name}}</strong>
                                <img v-if="sortDirections[index] == undefined" src="/img/sort_both.png" />
                                <img v-if="sortDirections[index] == true" src="/img/sort_asc.png" />
                                <img v-if="sortDirections[index] == false" src="/img/sort_desc.png" />
                            </button>

                            <div v-if="headerColumn.nameHtml != null">
                                <span v-html="headerColumn.nameHtml"></span>
                            </div>
                        </slot>
                    </th>
                </tr>
            </thead>
            <tbody>
                <slot v-if="filteredRowData.length == 0" name="no-data">
                    <tr>
                        <td :colspan="localColumnNames.length">
                            <p class="text-center">{{emptyRowMessage}}</p>
                        </td>
                    </tr>
                </slot>
                <tr v-for="(row, rowNum) in filteredRowData" :key="row.rowNum" v-on:click="emitRowTriggerEvent(row)">
                    <slot name="row"
                          :row="row"
                          :row-num="rowNum">
                        <td v-for="cell in row" :key="cell">
                            <slot name="cell"
                                  :cell="cell"
                                  :row="row">
                                {{cell}}
                            </slot>
                        </td>
                    </slot>
                </tr>
            </tbody>
        </table>
        <div v-if="filteredRowData.length > 0" class="row">
            <div class="col-4">
                <slot v-if="hasItemsPerPage" name="itemsPerPage">
                    <v-grid-num-row-selector v-model:items-per-page="itemsPerPage"></v-grid-num-row-selector>
                </slot>
            </div>
            <div class="col-8">
                <slot v-if="hasPagination" name="pagination">
                    <v-grid-pagination :number-of-items="numberOfItems"
                                       v-model:page-number="pageNumber"
                                       :items-per-page="itemsPerPage"
                                       :number-of-pages="numberOfPages"
                                       v-on:before-page-jump="beforePageJump"
                                       v-on:after-page-jump="afterPageJump"></v-grid-pagination>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        emits: ["before-page-jump", "after-page-jump", "row-trigger"],
        props: {
            columnNames: Array,
            rows: Array,
            sortedColumn: -1,
            sortedColumnIsAscending: { type: Boolean, default: true },
            caseSensitiveSort: { type: Boolean, default: false },
            hasFilter: { type: Boolean, default: true },
            filterOnAllFields: false,
            hasItemsPerPage: { type: Boolean, default: true },
            hasPagination: { type: Boolean, default: true },
            emptyRowMessage: { type: String, default: "No data to display." },
            tabindex: 0 // Swallow it.  The UI doesn't respond to the tabs for now.
        },
        data: () => ({
            localColumnNames: Array,
            numberOfItems: 0,
            pageNumber: 0,
            itemsPerPage: 10,
            numberOfPages: 0,
            filteredRowData: [],
            sortDirections: [],
            sortedColumnIndex: -1,
            filterText: '',
            isFiltered: false,
            textFilters: []
        }),
        methods: {
            emitRowTriggerEvent(rowNum) {
                this.$emit("row-trigger", rowNum);
            },
            triggerSort: function (headerColumnIndex) {
                for (const index in this.sortDirections) {
                    if (headerColumnIndex != index) {
                        this.sortDirections[index] = undefined;
                    }
                }

                this.sortDirections[headerColumnIndex] = !this.sortDirections[headerColumnIndex];
                this.sortedColumnIndex = headerColumnIndex;
                this.updateFilteredRowData();
            },
            updateFilteredRowData: function () {
                if (this.sortedColumnIndex == -1) {
                    this.filteredRowData = this.rows;
                }

                if (this.rows?.length == 0) {
                    this.filteredRowData.length = 0;
                    return;
                }

                let filteredRows = [];
                if (this.textFilters == '') {
                    filteredRows.push(...this.rows);
                } else {
                    if (this.filterOnAllFields == true || this.filterOnAllFields == "true") {
                        this.performFilterOnAllFields(filteredRows);
                    } else {
                        this.performFilterOnVisibleFields(filteredRows);
                    }
                }

                let sortedRowData = [];
                sortedRowData.push(...filteredRows);

                if (this.sortedColumnIndex > -1) {
                    let sortedColumnName = this.localColumnNames[this.sortedColumnIndex].columnName;
                    let numeric = this.localColumnNames[this.sortedColumnIndex].isNumeric;
                    let isNumericField = numeric == true || numeric == "true";

                    sortedRowData.sort((left, right) => {
                        let leftValue;
                        let rightValue;

                        if (this.caseSensitiveSort == true || this.caseSensitiveSort == "true") {
                            leftValue = left[sortedColumnName] ?? "";
                            rightValue = right[sortedColumnName] ?? "";
                        } else if (isNumericField) {
                            leftValue = left[sortedColumnName] ?? 0;
                            rightValue = right[sortedColumnName] ?? 0;
                        } else {
                            leftValue = (left[sortedColumnName] + "").toUpperCase();
                            rightValue = (right[sortedColumnName] + "").toUpperCase();
                        }

                        if (this.sortDirections[this.sortedColumnIndex] == true) {
                            return leftValue > rightValue ? 1 : -1;
                        } else {
                            return leftValue < rightValue ? 1 : -1
                        }
                    });
                }

                let start = this.pageNumber * this.itemsPerPage;

                this.numberOfPages = Math.ceil(sortedRowData.length / this.itemsPerPage);

                // Only include the current page set.
                this.filteredRowData = sortedRowData.slice(start, start + this.itemsPerPage);
                this.numberOfItems = this.filteredRowData.length;
            },
            performFilterOnAllFields(filteredRows) {
                this.rows.forEach(row => {
                    let includeRow = true;
                    this.textFilters.forEach(filter => {
                        let filterMatched = false;
                        if (filter != '') {
                            filter = filter.toLowerCase();
                            Object.getOwnPropertyNames(row).forEach(columnName => {
                                if (includeRow && !filterMatched) {
                                    let rawColumnValue = row[columnName];
                                    if (rawColumnValue != null && rawColumnValue != '') {
                                        let columnValue = String(rawColumnValue).toLowerCase()
                                        if (columnValue.includes(filter)) {
                                            filterMatched = true;
                                        }
                                    }
                                }
                            });
                        } else {
                            filterMatched = true;
                        }

                        if (!filterMatched) {
                            includeRow = false;
                        }
                    });

                    if (includeRow) {
                        filteredRows.push(row);
                    }
                });
            },
            performFilterOnVisibleFields(filteredRows) {
                this.rows.forEach(row => {
                    let includeRow = true;
                    this.textFilters.forEach(filter => {
                        let filterMatched = false;
                        if (filter != '') {
                            filter = filter.toLowerCase();
                            this.localColumnNames.forEach(columnNameProperty => {
                                let columnName = columnNameProperty.columnName;
                                if (columnName != undefined && columnName != null && includeRow && !filterMatched) {
                                    let rawColumnValue = row[columnName];
                                    if (rawColumnValue != null && rawColumnValue != '') {
                                        let columnValue = String(rawColumnValue).toLowerCase()
                                        if (columnValue.includes(filter)) {
                                            filterMatched = true;
                                        }
                                    }
                                }
                            });
                        } else {
                            filterMatched = true;
                        }

                        if (!filterMatched) {
                            includeRow = false;
                        }
                    });

                    if (includeRow) {
                        filteredRows.push(row);
                    }
                });
            },
            beforePageJump(fromPageNumber, toPageNumber) {
                this.$emit("before-page-jump", fromPageNumber, toPageNumber);
            },
            afterPageJump(fromPageNumber, toPageNumber) {
                this.$emit("after-page-jump", fromPageNumber, toPageNumber);
            },
            processFilter() {
                this.textFilters = this.filterText.split(' ');
                this.updateFilteredRowData();
            },
            createColumnNames() {
                if (!(this.rows?.length > 0)) {
                    return;
                }
                this.localColumnNames = [];
                for (let columnName in this.rows[0]) {
                    this.localColumnNames.push({
                        name: columnName,
                        columnName: columnName
                    });
                }
            }
        },
        name: "v_grid",
        watch: {
            rows: {
                handler(newRowData) {
                    this.updateFilteredRowData();
                },
                deep: true
            },
            pageNumber(newPageNumber) {
                this.updateFilteredRowData();
            },
            itemsPerPage(newItemsPerPage) {
                this.updateFilteredRowData();
            },
            filterText(newValue, oldValue) {
                this.pageNumber = 0;
                this.processFilter();
            }
        },
        created() {
            this.rowData = this.rows;

            // Width can be like "12%" or "12px" but not both.

            let requiredWidth = 0;
            let unrequiredHeaders = 0;
            let pxFound = false;

            if (this.columnNames?.length > 0) {
                this.localColumnNames = [];
                this.localColumnNames.push(...this.columnNames);
            } else {
                this.createColumnNames();
            }

            if (isNaN(parseInt(this.sortedColumn))) {
                for (let idx in this.localColumnNames) {
                    let column = this.localColumnNames[idx];
                    if (column.columnName == this.sortedColumn) {
                        this.sortedColumnIndex = idx;
                    }
                }
            } else {
                this.sortedColumnIndex = this.sortedColumn ?? 0;
            }

            for (const index in this.localColumnNames) {
                let column = this.localColumnNames[index];
                if (column.width == undefined || column.width == 0) {
                    unrequiredHeaders++;
                    column.width = 0;
                } else {
                    let updatedWidth = column.width;
                    if (column.width.slice(-1) == "%") {
                        updatedWidth = column.width.substr(0, column.width.length - 1);
                    } else if (column.width.slice(-2) == "px") {
                        updatedWidth = column.width.substr(0, column.width.length - 2);
                        pxFound = true;
                    }

                    requiredWidth += Number(updatedWidth);
                }

                if (this.sortedColumnIndex > -1) {
                    if (index == this.sortedColumnIndex) {
                        this.sortDirections.push(this.sortedColumnIsAscending);
                    } else {
                        this.sortDirections.push(undefined);
                    }
                } else {
                    this.sortDirections.push(index == 0 ? true : undefined);
                }
            }

            if (!pxFound) {
                let unspecifiedWidth = (100 - requiredWidth) / unrequiredHeaders;
                for (const index in this.localColumnNames) {
                    let column = this.localColumnNames[index];
                    if (column.width == undefined || column.width == 0) {
                        column.width = unspecifiedWidth + "%";
                    }
                }
            }

            this.updateFilteredRowData();
        }
    }
</script>
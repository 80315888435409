const StatusCodes = {
    // START: System

    NoError: 1,
    FatalError: 2,
    PreferenceNameNotFound: 3,
    ServerUnavailable: 4,
    UnableToSendEmail: 5,
    BadParametersPassed: 6,
    UploadedFileHasInvalidExtension: 7,

    // END: System

    // START: User

    EmailAddressDoesNotExist: 1000,
    UserExistsButWrongHash: 1001,
    UserExistsWithCorrectHashButIsInactive: 1002,
    SomeOtherUserError: 1003,
    UseRoleNotFoundById: 1004,
    CannotCreateSinceEmailAddressIsAlreadyActive: 1005,
    UsernameAlreadyExistsAndIsActive: 1006,
    UserNotFoundById: 1007,
    UserAccountGuidNotFound: 1008,
    UserAccountGuidAlreadyVerified: 1009,
    UserInvitationActiveAlreadyPending: 1010,
    UserInvitationAlreadyLinked: 1011,
    UserGuidNotFound: 1012,
    UserPaymentCorrelationAlreadyExists: 1013,
    UserRoleNotChanged: 1014,

    // END: User

    // START: Plan

    PlanLimitUserExceeded: 2000,
    PlanLimitVendorExceeded: 2001,
    PlanLimitContactExceeded: 2002,
    PlanLimitItemExceeded: 2003,
    PlanLimitLocationExceeded: 2004,
    PlanLookupKeyNotFound: 2005,

    // END: Plan

    // END: Plan Limits

    // START: Contact

    ContactAlreadyExists: 3000,
    ContactMissingName: 3001,
    ContactCouldNotDelete: 3002,

    // END: Contact

    // START: Item Type

    ItemTypeAlreadyExists: 4000,

    // END: Item Type

    // START: Item

    ItemAlreadyExists: 5000,

    // END: Item

    // START: Client

    ClientIdDoesNotExist: 6000,
    FeeAssessmentPeriodIdDoesNotExist: 6001,
    ClientUserRoleRemoveRemovedByUserIdNotFound: 6002,
    ClientUserRoleRecordNotFound: 6003,

    // END: Client

    // START: Vendor

    VendorAlreadyExists: 7000,
    VendorMissingName: 7001,
    VendorCouldNotDelete: 7002,

    // END: Vendor
}

export { StatusCodes }

import ValidationMixin from "/wwwroot/js/vue/validationMixin.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

const VendorEdit = {
    mixins: [ValidationMixin],
    emits: ["updated:edit-vendor-model"],
    props: {
        globalConfigViewModel: null,
        messageBoxModel: null,
        editVendorModel: null,
        recordIsReadOnly: false,
        stateListItemString: null,
        loadTrigger: 0
    },
    data: () => ({
        resetCurrentFeeAssessmentTrigger: 0,
        clientId: 0,
        id: 0,
        businessName: null,
        firstName: null,
        lastName: null,
        phone: null,
        mobile: null,
        fax: null,
        emailAddress: null,
        address1: null,
        address2: null,
        city: null,
        stateId: null,
        zip: null,
        zipPlus4: null,
        website: null,
        hasFeeAssessment: null,
        currentFeeAssessment: null,
        isVendor: null,
        apiBody: null,
        apiTrigger: 0,
        tabList: null,
        isEditVendorReadOnly: false,
        validationNeeded: false,
        validationMessages: [],
        feeAssessmentClearTrigger: 0,
        feeAssessmentClearBody: null
    }),
    methods: {
        closeButtonClick() {
            this.globalVendorEditViewModel.isEditVendorVisible = false;
        },
        getCurrentFeeAssessment() {
            let cfa = this.currentFeeAssessment;
            if (cfa == null || cfa == 0) {
                return null;
            }
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            let formatted = formatter.format(cfa);
            return formatted;
        },
        clearDesignerAssessment(reason) {
            if (reason == null || reason.length == 0) {
                MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                    body: "If you meant to clear this designer's assessment, you must enter a reason.",
                    title: "Error",
                    messageBoxType: MessageBoxType.error
                });
            } else {
                this.feeAssessmentClearBody = {
                    vendorId: this.editVendorModel.id,
                    reason: reason
                };
                this.feeAssessmentClearTrigger++;
            }
        },
        feeAssessmentClearResponse(response) {
                        this.currentFeeAssessment = 0;
                        this.hasFeeAssessment = false;
                        this.editVendorModel.currentFeeAssessment = 0;
                        this.editVendorModel.hasCurrentFeeAssessment = false;
        },
        saveChanges() {
            this.pushEditVendorModel();
            this.setValidationNeeded();
        },
        validationSuccessful() {
            console.log("validation success");
            this.apiBody = {
                clientId: this.clientId,
                id: this.id,
                isVendor: this.isVendor,
                businessName: this.businessName,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                mobile: this.mobile,
                fax: this.fax,
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                stateId: this.stateId,
                zip: this.zip,
                zipPlus4: this.zipPlus4,
                emailAddress: this.emailAddress,
                website: this.website,
                currentFeeAssessment: this.currentFeeAssessment
            };
            this.apiTrigger = new Date().getTime();
        },
        upsertResponse(response) {
            if (this.editVendorModel.id == undefined || this.editVendorModel.id == 0) {
                this.editVendorModel.id = response.data.envelope.id;
            }
            console.log("response", response);
            this.$emit("updated:edit-vendor-model", this.editVendorModel);
            this.globalVendorEditViewModel.editVendorModelUpdated = true;
            this.globalVendorEditViewModel.isEditVendorVisible = false;
        },
        pullEditVendorModel() {
            this.clientId = this.editVendorModel.clientId;
            this.id = this.editVendorModel.id;
            this.businessName = this.editVendorModel.businessName;
            this.firstName = this.editVendorModel.firstName;
            this.lastName = this.editVendorModel.lastName;
            this.phone = this.editVendorModel.phone;
            this.mobile = this.editVendorModel.mobile;
            this.fax = this.editVendorModel.fax;
            this.emailAddress = this.editVendorModel.emailAddress;
            this.address1 = this.editVendorModel.address1;
            this.address2 = this.editVendorModel.address2;
            this.city = this.editVendorModel.city;
            this.stateId = this.editVendorModel.stateId;
            this.zip = this.editVendorModel.zip;
            this.zipPlus4 = this.editVendorModel.zipPlus4;
            this.website = this.editVendorModel.website;
            this.hasFeeAssessment = this.editVendorModel.hasFeeAssessment;
            this.currentFeeAssessment = this.editVendorModel.currentFeeAssessment;
            this.isVendor = this.editVendorModel.isVendor;
        },
        pushEditVendorModel() {
            this.editVendorModel.clientId = this.clientId;
            this.editVendorModel.id = this.id;
            this.editVendorModel.businessName = this.businessName;
            this.editVendorModel.firstName = this.firstName;
            this.editVendorModel.lastName = this.lastName;
            this.editVendorModel.phone = this.phone;
            this.editVendorModel.mobile = this.mobile;
            this.editVendorModel.fax = this.fax;
            this.editVendorModel.emailAddress = this.emailAddress;
            this.editVendorModel.address1 = this.address1;
            this.editVendorModel.address2 = this.address2;
            this.editVendorModel.city = this.city;
            this.editVendorModel.stateId = this.stateId;
            this.editVendorModel.zip = this.zip;
            this.editVendorModel.zipPlus4 = this.zipPlus4;
            this.editVendorModel.website = this.website;
            this.editVendorModel.hasFeeAssessment = this.hasFeeAssessment;
            this.editVendorModel.currentFeeAssessment = this.currentFeeAssessment;
            this.editVendorModel.isVendor = this.isVendor;

            this.editVendorModel.displayName = this.businessName != ""
                ? this.businessName
                : `${this.firstName} ${this.lastName}`;
        },
        initialize() {
                    this.isEditVendorReadOnly = this.globalConfigViewModel.isEditVendorReadOnly;
                    this.pullEditVendorModel();
        }
    },
    computed: {
        isEditVendorVisible: {
            get() {
                return this.globalVendorEditViewModel.isEditVendorVisible;
            }
        }
    },
    watch: {
        resetCurrentFeeAssessmentTrigger(newValue, oldValue) {
            console.log("watch response from resetCurrentfeeAssessmentTrigger");
            MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                body: "Are you sure you want to clear this designer's assessment?  You must provide a reason.",
                title: "Attention",
                inputLabel: "Reason",
                rightButtonText: "Yes",
                middleButtonText: "No",
                messageBoxType: MessageBoxType.input,
                rightButtonAction: (reason) => this.clearDesignerAssessment(reason)
            });
        },
        globalVendorEditViewModel: {
            handler(newValue, oldValue) {
                console.log("watch response from globalVendorEditViewModel");
                console.log("vendor_edit.globalVendorEditViewModel");
                if (this.isEditVendorVisible == true) {
                    this.initialize();
                }
            },
            deep: true
        }
    },
    created() {
        this.initialize();
    },
    template: "#VendorEdit"
};
export default VendorEdit;

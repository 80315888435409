import ValidationMixin from "/wwwroot/js/vue/validationMixin.js";
import { StatusCodes } from "/wwwroot/js/common/statusCodes.js";
import { MessageBoxType, MessageBoxHelper } from "/wwwroot/js/common/messageBox.js";

const ContactEditModal = {
    mixins: [ValidationMixin],
    emits: ["updated:edit-contact-model"],
    props: {
        clientId: 0,
        globalConfigViewModel: null,
        messageBoxModel: null,
        editContactModel: null,
        recordIsReadOnly: false,
        emptyItemModel: null
    },
    data: () => ({
        apiBody: null,
        apiTrigger: 0,
        tabList: null,
        contactEditLoadTrigger: 0,
        contactCheckoutLoadTrigger: 0,
        contactCurrentLoadTrigger: 0,
        contactHistoryLoadTrigger: 0,
        tabIndex: 0
    }),
    methods: {
        upsertError(error) {
            console.log("ERROR", error);
        },
        upsertResponse(response) {
            switch (response.data.responseHeader.statusCode) {
                case StatusCodes.NoError:
                    this.$emit("update:edit-contact-model", response.data.envelope);
                    this.globalContactEditViewModel.isEditContactVisible = false;
                    break;

                case StatusCodes.ContactAlreadyExists:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "A designer with this name combination already exists.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;

                case StatusCodes.ContactMissingName:
                    MessageBoxHelper.displayMessageBox(this.messageBoxModel, {
                        title: "Attention",
                        body: "A valid name combination is missing.  Please update it and try again.",
                        messageBoxType: MessageBoxType.error
                    });
                    break;

                default:
                    console.log("UNKNOWN", response);
                    break;
            }
        },
        closeButtonClick: function () {
            this.globalContactEditViewModel.isEditContactVisible = false;
        },
    },
    computed: {
        isEditContactVisible: {
            get() {
                return this.globalContactEditViewModel.isEditContactVisible;
            }
        }
    },
    watch: {
        editContactModel(newValue, oldValue) {
            console.log("watch response from editContactModel");
            this.$emit("updated:edit-contact-model", newValue);
        },
        globalContactEditViewModel: {
            handler(newValue, oldValue) {
                console.log("watch response from globalContactEditViewModel");
                if (this.isEditContactVisible == true) {
                    let tabList = [
                        {
                            label: "Contact",
                            class: "",
                            visible: true,
                            beforeChange: () => {
                                this.contactEditLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "Checkout",
                            class: "",
                            visible: true,
                            beforeChange: () => {
                                this.contactCheckoutLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "Current",
                            class: "",
                            visible: true,
                            beforeChange: () => {
                                this.contactCurrentLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        },
                        {
                            label: "History",
                            class: "",
                            visible: true,
                            beforeChange: () => {
                                this.contactHistoryLoadTrigger++;
                            },
                            afterChange: () => {
                            }
                        }
                    ];

                    this.tabList = tabList;
                }
            },
            deep: true
        }
    },
    created() {
    },
    template: "#ContactEditModal"
};

export default ContactEditModal;

const VendorInfoCard = {
    emits: ["update:checked-out-vendor"],
    props: {
        checkedOutVendor: null,
        stateList: null
    },
    data: () => ({
    }),
    methods: {
        closeButtonClick() {
            this.$emit("update:checked-out-vendor", null);
        }
    },
    computed: {
        businessName: {
            get() {
                return this.checkedOutVendor?.businessName;
            }
        },
        address1: {
            get() {
                return this.checkedOutVendor?.address1;
            }
        },
        firstName: {
            get() {
                return this.checkedOutVendor?.firstName;
            }
        },
        address2: {
            get() {
                return this.checkedOutVendor?.address2;
            }
        },
        lastName: {
            get() {
                return this.checkedOutVendor?.lastName;
            }
        },
        city: {
            get() {
                return this.checkedOutVendor?.city;
            }
        },
        phone: {
            get() {
                return this.checkedOutVendor?.phone;
            }
        },
        state: {
            get() {
                let s = this.stateList.filter(st => st.value == this.checkedOutVendor?.stateId);
                return s.length > 0
                    ? s[0].text
                    : "";
            }
        },
        mobile: {
            get() {
                return this.checkedOutVendor?.mobile;
            }
        },
        zip: {
            get() {
                return this.checkedOutVendor?.zip;
            }
        },
        fax: {
            get() {
                return this.checkedOutVendor?.fax;
            }
        },
        zipPlus4: {
            get() {
                return this.checkedOutVendor?.zipPlus4;
            }
        },
        email: {
            get() {
                return this.checkedOutVendor?.email;
            }
        },
        website: {
            get() {
                return this.checkedOutVendor?.website;
            }
        }
    },
    template: "#VendorInfoCard"
};

export default VendorInfoCard;

const guidHelperFormats = {
    short: 1,
    long: 2
}

const guidHelper = {
    getNewGuid(prefix = "G", guidHelperFormat = guidHelperFormats.short) {
        let format = '';
        switch (guidHelperFormat) {
            case guidHelperFormats.short:
                format = 'xxxxxxxx';
                break;

            case guidHelperFormats.long:
                format = 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx';
                break;
        }

        return prefix + format.replace(/[xy]/g,
            function (c) {
                var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
                return uuid.toString(16);
            });
    }
}

export { guidHelper, guidHelperFormats };
